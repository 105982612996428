document.addEventListener('turbolinks:load', () => {
  let targets = document.getElementsByClassName('trianglify')
  let mc = document.getElementById('main-container')
  if (targets.length) {
    targets.forEach(target => {
      if (!target.style.backgroundImage) {
        const options = target.dataset.trianglify ? JSON.parse(target.dataset.trianglify) : {}
        let pattern = trianglify({
          width: mc.offsetWidth,
          height: mc.offsetHeight,
          colorSpace: 'rgb',
          xColors: options.color ? options.color.trim().replace(/ /g, '').split(',') :'random',
          cellSize: options.size ? options.size : 75,
          variance: options.variance ? options.variance : 0.75
        })
        target.style.backgroundImage = `url("data:image/svg+xml;utf8,${pattern.toSVGTree().toString()}")`
      }
    })
  }
})
